<template>
	<div :class="{'block-inner': true, 'disabled': isDisabled}">
		<header v-html="question"></header>

		<draggable
			v-bind="dragOptions"
			:group="{
				name: 'items',
				put: false,
				pull: true
			}"
			:list="items"
			class="items"
		>
			<li v-for="(item, index) in items" :key="index" class="move-item">
				<span>{{ item.label }}</span>
				<IconMove :width="16" :height="16" />
			</li>
		</draggable>

		<ol v-for="target in block.targets" :key="target.id" class="targets">
			<li>
				<p class="target-name">
					{{ target.label }}
				</p>
				<draggable
					:list="target.items"
					:group="{
						name: target.id,
						put: ['items', ...block.targets.map(t => t.id)],
						pull: true
					}"
					v-bind="dragOptions"
					@add="save"
				>
					<li v-for="(item, index) in target.items" :key="index" :class="{'move-item': true, 'correct': showFeedback && !!item.isCorrect, 'incorrect': showFeedback && !item.isCorrect}">
						<span>{{ item.label }}</span>
						<IconMove :width="16" :height="16" />
					</li>
				</draggable>
			</li>
		</ol>
	</div>
</template>

<script>
	import {jsonToHtml} from '../../inc/text';
	import answerMixin from '../../mixins/answerMixin';
	import IconMove from '../icons/IconMove';
	import draggable from 'vuedraggable';
	import Store from '../../inc/store';
	import {showCourseFeedback} from '../../inc/courseUtils';

	export default {
		components: {
			IconMove,
			draggable
		},
		mixins: [answerMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				dragOptions: {
					animation: 200,
					handle: '.move-item',
					tag: 'ol',
					swapThreshold: 0.25,
					disabled: this.isDisabled
				},
				items: []
			};
		},
		computed: {
			question() {
				return jsonToHtml(this.block.content);
			},
			answer() {
				const groupAnswers = Store.groupAnswers.filter(answer => answer.blockId === this.block.id);

				if(groupAnswers.length) {
					return groupAnswers[0];
				}

				return {};
			},
			isSubmitted() {
				return Store.groupAnswers.some(answer => answer.blockId === this.block.id);
			},
			showFeedback() {
				return this.isSubmitted && showCourseFeedback(this.block.type);
			},
			isDisabled() {
				return Store.session.timeEnded || Store.courseGroupProgress.timeGraded;
			}
		},
		watch: {
			answer(value) {
				this.updateTargetItems(value);
			}
		},
		beforeMount() {
			if(Array.isArray(this.block.items)) {
				this.items = [...this.block.items];
			}

			if(Array.isArray(this.block.targets)) {
				this.updateTargetItems(this.answer);
			}
		},
		methods: {
			save() {
				const answers = this.block.targets.map(t => ({
					id: t.id,
					items: t.items.map(i => i.id)
				}));

				this.sendAnswer([answers]);
			},
			updateTargetItems(answer) {
				const addedItems = [];

				for(const target of this.block.targets) {
					target.items = [];

					if(!Array.isArray(answer.answers) || !answer.answers[0] || !answer.answers[0].length) {
						continue;
					}

					const targetAnswer = answer.answers[0].find(a => a.id === target.id);

					if(!targetAnswer) {
						continue;
					}

					for(const item of this.block.items) {
						if(targetAnswer.items.includes(item.id)) {
							addedItems.push(item.id);

							const targetItem = {
								...item
							};

							if(answer && Array.isArray(answer.correctAnswers)) {
								targetItem.isCorrect = answer.correctAnswers.includes(item.id);
							}

							target.items.push(targetItem);
						}
					}
				}

				if(addedItems.length) {
					this.items = this.block.items.filter(item => !addedItems.includes(item.id));
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.block-inner {
		display: flex;
		flex-flow: row wrap;
		column-gap: $default_padding * 2;

		header {
			flex: 1 0 100%;
			margin-bottom: 2rem;
		}

		.items, .targets {
			flex: 1;
		}

		&.disabled {
			.items > li,
			.targets ol li {
				cursor: default;
			}
		}
	}

	ol {
		list-style-type: none;
		margin: 0;
		padding: 0;
		max-width: 500px;
	}

	.move-item {
		&.correct {
			box-shadow: 0 0 0 1px $color__green;
		}

		&.incorrect {
			box-shadow: 0 0 0 1px $color__red;
		}
	}

	.items > li,
	.targets ol li {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		background: $color__white;
		font-size: 1.25rem;
		width: 100%;
		border: none;
		box-shadow: $box_shadow__input;
		border-radius: $border_radius;
		padding: 0.5rem 1rem;
		margin-bottom: $default_padding / 2;
		line-height: 1em;
		outline: none !important;
		transition: box-shadow 0.2s ease-out;
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}

	.targets > li {
		.target-name {
			text-align: center;
			color: $color__dark_blue;
			text-transform: uppercase;
			margin-bottom: 5px;
		}

		& > ol {
			min-height: 100px;
			border: 2px dashed $color__dark_blue;
			border-radius: $border_radius;
			padding: $default_padding * 0.5;
			position: relative;

			li {
				position: relative;
				z-index: 2;
			}

			&::before {
				content: 'Drop items here';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0.5;
				z-index: 1;
			}
		}
	}

</style>