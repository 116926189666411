var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{'block-inner': true, 'disabled': _vm.isDisabled}},[_c('header',{domProps:{"innerHTML":_vm._s(_vm.question)}}),_c('draggable',_vm._b({staticClass:"items",attrs:{"group":{
			name: 'items',
			put: false,
			pull: true
		},"list":_vm.items}},'draggable',_vm.dragOptions,false),_vm._l((_vm.items),function(item,index){return _c('li',{key:index,staticClass:"move-item"},[_c('span',[_vm._v(_vm._s(item.label))]),_c('IconMove',{attrs:{"width":16,"height":16}})],1)}),0),_vm._l((_vm.block.targets),function(target){return _c('ol',{key:target.id,staticClass:"targets"},[_c('li',[_c('p',{staticClass:"target-name"},[_vm._v(" "+_vm._s(target.label)+" ")]),_c('draggable',_vm._b({attrs:{"list":target.items,"group":{
					name: target.id,
					put: ['items' ].concat( _vm.block.targets.map(function (t) { return t.id; })),
					pull: true
				}},on:{"add":_vm.save}},'draggable',_vm.dragOptions,false),_vm._l((target.items),function(item,index){return _c('li',{key:index,class:{'move-item': true, 'correct': _vm.showFeedback && !!item.isCorrect, 'incorrect': _vm.showFeedback && !item.isCorrect}},[_c('span',[_vm._v(_vm._s(item.label))]),_c('IconMove',{attrs:{"width":16,"height":16}})],1)}),0)],1)])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }